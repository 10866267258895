import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import type { ApartmentSelectOptions } from '../types'
import type { RegistryMeter } from './types'
import { registryMeterHeaders } from './constants'
import { getMeterRegistryRowValue } from './utils'

type Props = {
	registryMeter: RegistryMeter
	menuItems: any[] | null
	children: any
	processing: any
	apartmentOptions: ApartmentSelectOptions[]
}

const MeterRegistryTableRow = ({
	registryMeter,
	menuItems,
	children,
	processing,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?muuid=' + registryMeter.uuid))
	}

	const renderTableColumns = () => {
		return registryMeterHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={handleOnClick} key={key}>
						<p>
							{getMeterRegistryRowValue(
								registryMeter,
								key,
								intl,
								apartmentOptions,
								type,
							)}
						</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={registryMeter.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default MeterRegistryTableRow
