import { Route, Switch } from 'react-router'
import styled from 'styled-components'
import RouteHeader from 'ui/components/RouteHeader'
import { useIsSuperAdmin } from 'ui/components/userRoles/hooks'
import Parties from './Parties'
import Contracts from './Contracts'
import { useIntl } from 'react-intl'
import FinanceSettings from './Settings'
import PaymentProducts from './PaymentProducts'
import { useDispatch, useSelector } from 'react-redux'
import { _setSelectedFinanceCompany } from 'state/appActions'
import type { Manager } from 'types/manager'
import WithSelectedManagerCompanies from 'ui/components/WithSelectedManagerCompanies'
import { SelectField } from 'react-md'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Invoices from './Invoices'
import Payments from './Payments'
import AccountsLedger from './AccountsLedger'
import type { SelectOptions } from './types'
import Loans from './Loans'
import useSettings from './Settings/useSettings'
import { push } from 'connected-react-router'
import config from 'config'
import useDialogs from 'ui/components/dialogs/useDialogs'
import MeterRegistry from './MeterRegistry'

const Container = styled.div``
const CompanySelectionContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90vh;
`

const CompanySelectionContainerSmall = styled.div`
	display: flex;
	justify-content: left;
	align-items: left;
	margin-left: 10px;
	margin-right: 10px;
	height: 50px;
	border: 1px solid rgba(0, 0, 0, 0.71);
`

const CompanySelectionContainerHeader = styled.div`
	display: flex;
	justify-content: left;
	align-items: left;
	margin-left: 10px;
	height: 100%;
	width: 200px;
	border: 1px solid rgba(0, 0, 0, 0.71);
`

const Content = styled.div`
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const RouteContent = styled.div``

const createPath = (path: string) => {
	return `/manager/finance${path}`
}
const HASH_BILLING = config.routeHashes.billing

export const financeRoutes = [
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Osapuolet',
				description: 'Title of an app section for finance: Parties page.',
			}),
		path: createPath('/parties'),
		exact: true,
		component: () => <Parties />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'group',
		public: true,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Maksulajit',
				description: 'Title of an app section for finance: Payment page.',
			}),
		path: createPath('/payment_products'),
		exact: true,
		component: (props) => (
			<PaymentProducts
				selectedManagerCompanies={props.selectedManagerCompanies}
			/>
		),
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'menu',
		public: true,
		hidden: false,
	},

	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Sopimukset',
				description: 'Title of an app section for finance: Contract page.',
			}),
		path: createPath('/contracts'),
		exact: true,
		component: () => <Contracts />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'description',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Laskut',
				description: 'Title of an app section for finance: Invoices page.',
			}),
		path: createPath('/invoices'),
		exact: true,
		component: () => <Invoices />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'receipt',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Suoritukset',
				description: 'Title of an app section for finance: Payment  page.',
			}),
		path: createPath('/payments'),
		exact: true,
		component: () => <Payments />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'attach_money',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Reskontra',
				description:
					'Title of an app section for finance: Accounts ledger page.',
			}),
		path: createPath('/accountsLedger'),
		exact: true,
		component: () => <AccountsLedger />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'checklist',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Lainat',
				description: 'Title of an app section for finance: Loans page.',
			}),
		path: createPath('/loans'),
		exact: true,
		component: () => <Loans />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'account_balance',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Mittarirekisteri',
				description:
					'Title of an app section for finance: Meter registry page.',
			}),
		path: createPath('/meterRegistry'),
		exact: true,
		component: () => <MeterRegistry />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'sensors',
		public: false,
		hidden: false,
	},
	{
		title: (intl) =>
			intl.formatMessage({
				defaultMessage: 'Asetukset',
				description: 'Title of an app section for finance: Settings page.',
			}),
		path: createPath('/settings'),
		exact: true,
		component: () => <FinanceSettings />,
		category: 'finance',
		feature: 'finance',
		disabled: false,
		icon: 'settings',
		public: false,
		hidden: false,
	},
]

type Props = {
	selectedManagerCompanies: Manager[]
	isDesktop: boolean
}

function ManagerFinance({ selectedManagerCompanies, isDesktop }: Props) {
	const dispatch = useDispatch()
	const selectedFinanceCompanyUUID = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)
	const router: any = useSelector(({ router }: any) => router)
	const intl = useIntl()
	const { alert } = useDialogs()

	const isSuperAdmin = useIsSuperAdmin()

	const { settings, loadingSettings } = useSettings(
		selectedFinanceCompanyUUID,
		!isSuperAdmin,
	)

	if (!isSuperAdmin) {
		return null
	}

	const handleSelectCompany = (uuid: string) => {
		dispatch(_setSelectedFinanceCompany({ uuid: uuid }))
	}

	let companyOptions: SelectOptions[] = []
	if (selectedManagerCompanies && selectedManagerCompanies.length > 0) {
		companyOptions = selectedManagerCompanies.map((role: Manager) => {
			return {
				label: role.name,
				value: role.uuid,
			}
		})
	}
	// Render the button when no company UUID is selected
	const renderCompanySelection = () => {
		return (
			<SelectField
				id="company"
				placeholder={intl.formatMessage({
					defaultMessage: 'Kohde',
					description: 'Placeholder of company',
				})}
				value={selectedFinanceCompanyUUID}
				onChange={(value) => handleSelectCompany(value as string)}
				menuItems={companyOptions}
				position={SelectField.Positions.BELOW}
				simplifiedMenu={false}
				listHeightRestricted
				style={{
					width: '100%',
					background: '#fafafa',
				}}
			/>
		)
	}

	if (
		!selectedFinanceCompanyUUID ||
		!companyOptions.find((c) => c.value === selectedFinanceCompanyUUID)
	) {
		return (
			<Container>
				<RouteHeader header="Talous" />
				<CompanySelectionContainer>
					<Content>
						<p>
							{intl.formatMessage({
								defaultMessage:
									'Valitse kohde aloittaaksesi ominaisuuden käyttö',
								description:
									'Paragraph text of select company before you can start using finance section',
							})}
						</p>
						{renderCompanySelection()}
					</Content>
				</CompanySelectionContainer>
			</Container>
		)
	}

	const hasRequiredSettings = () => {
		if (loadingSettings) return true
		if (!settings) return false
		const {
			referenceNumberPrefix,
			invoicePrefix,
			dueDate,
			collectionFee,
			invoicingStartMonth,
		} = settings
		return Boolean(
			referenceNumberPrefix &&
				invoicePrefix &&
				dueDate &&
				Number(collectionFee) >= 0 &&
				invoicingStartMonth,
		)
	}

	const getRoute = () => {
		let relativePath = '/'
		let path = router && router.location && router.location.pathname

		if (path) {
			const company = path.split('/')[1]
			relativePath = path.split(company)[1]
			if (!relativePath) relativePath = '/'
		}

		const route = financeRoutes.find(
			(r) => r.path === path || r.path + '/' === path,
		)

		if (selectedFinanceCompanyUUID && path !== '/manager/finance/settings') {
			if (!hasRequiredSettings()) {
				const alertMSG = intl.formatMessage({
					defaultMessage:
						'Täytä laskutuksen asetukset jatkaaksesi talousominaisuuden käyttöä.',
					description:
						'Fill in the billing settings to continue using the finance feature.',
				})
				alert(alertMSG)
				dispatch(push(createPath('/settings?onboarding' + HASH_BILLING)))
			}
		}
		if (!route) {
			//TODO: NOT FOUND PAGE
			return {
				title: (intl) =>
					intl.formatMessage({
						defaultMessage: 'Talous',
						description: 'Title of an app section for finance: Finance.',
					}),
			}
		}
		return route
	}

	const route = getRoute()

	return (
		<Container>
			{isDesktop ? (
				<RouteHeader
					header={route.title(intl)}
					selectField={
						<CompanySelectionContainerHeader>
							{renderCompanySelection()}
						</CompanySelectionContainerHeader>
					}
				/>
			) : (
				<>
					<RouteHeader header={route.title(intl)} />
					<CompanySelectionContainerSmall>
						{renderCompanySelection()}
					</CompanySelectionContainerSmall>
				</>
			)}
			<RouteContent>
				<Switch>
					{financeRoutes.map((route) => (
						<Route key={route.path} path={route.path} exact={route.exact}>
							{route.component({ selectedManagerCompanies })}
						</Route>
					))}
					<Route>Not found.</Route>
				</Switch>
			</RouteContent>
		</Container>
	)
}

const mapState = ({ dimensions: { isDesktop } }) => ({
	isDesktop,
})

export default compose(
	WithSelectedManagerCompanies({ hideLoading: false }),
	connect(mapState),
)(ManagerFinance)
