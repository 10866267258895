import { IntlShape } from 'react-intl'
import {
	PaymentProductBillingBasis,
	BondName,
	Direction,
	InvoicingMethod,
	TaxPeriodLength,
	InvoiceStatus,
	CountryCode,
	InvoicingMonth,
	AccountsLedgerGroupBy,
	AccountsLedgerView,
	MeterLocation,
	MeterTypeKey,
	ReadingMethodKey,
	ReadingStatus,
} from '../enum'

export const billingBasisMessage = (
	intl: IntlShape,
	billingBasis: PaymentProductBillingBasis,
) => {
	switch (billingBasis) {
		case PaymentProductBillingBasis.AREA:
			return intl.formatMessage({
				defaultMessage: 'Pinta-ala',
				description: 'Label for payment product billing basis area.',
			})
		case PaymentProductBillingBasis.AREA_ADJUSTED:
			return intl.formatMessage({
				defaultMessage: 'Jyvitetty pinta-ala',
				description: 'Label for payment product billing basis adjusted area.',
			})
		case PaymentProductBillingBasis.SHARE_COUNT:
			return intl.formatMessage({
				defaultMessage: 'Osakemäärä',
				description: 'Label for payment product billing basis share count.',
			})
		case PaymentProductBillingBasis.RESIDENT_COUNT:
			return intl.formatMessage({
				defaultMessage: 'Asukasmäärä',
				description: 'Label for payment product billing basis resident count.',
			})
		case PaymentProductBillingBasis.PIECES:
			return intl.formatMessage({
				defaultMessage: 'Kappalemäärä',
				description: 'Label for payment product billing basis pieces.',
			})
		default:
			return ''
	}
}

export const unitMessage = (
	intl: IntlShape,
	billingBasis: PaymentProductBillingBasis,
) => {
	switch (billingBasis) {
		case PaymentProductBillingBasis.AREA:
			return intl.formatMessage({
				defaultMessage: 'm²',
				description: 'Label for payment product unit area.',
			})
		case PaymentProductBillingBasis.AREA_ADJUSTED:
			return intl.formatMessage({
				defaultMessage: 'Jyv. m²',
				description: 'Label for payment product unit adjusted area.',
			})
		case PaymentProductBillingBasis.SHARE_COUNT:
			return intl.formatMessage({
				defaultMessage: 'kpl',
				description: 'Label for payment product unit share count.',
			})
		case PaymentProductBillingBasis.RESIDENT_COUNT:
			return intl.formatMessage({
				defaultMessage: 'As. lkm',
				description: 'Label for payment product unit resident count.',
			})
		case PaymentProductBillingBasis.PIECES:
			return intl.formatMessage({
				defaultMessage: 'kpl',
				description: 'Label for payment product unit pieces.',
			})
		default:
			return ''
	}
}

export const bondNameMessage = (
	intl: IntlShape,
	bondName: BondName | string,
) => {
	switch (bondName) {
		case BondName.TENANT:
			return intl.formatMessage({
				defaultMessage: 'Asukas',
				description: 'Label for bond name: Tenant.',
			})
		case BondName.SHAREHOLDER:
			return intl.formatMessage({
				defaultMessage: 'Osakas',
				description: 'Label for bond name: Shareholder.',
			})
		case BondName.SHAREHOLDER_NOT_REGISTERED:
			return intl.formatMessage({
				defaultMessage: 'Osakas (ei rekisteröity)',
				description: 'Label for bond name: Shareholder not registered',
			})
		default:
			return ''
	}
}

export const invoicingMethodMessage = (
	intl: IntlShape,
	method: InvoicingMethod | string,
) => {
	switch (method) {
		case InvoicingMethod.E_INVOICE:
			return intl.formatMessage({
				defaultMessage: 'E-lasku',
				description: 'Label for invoicing method e-invoice.',
			})
		case InvoicingMethod.WEB_INVOICE:
			return intl.formatMessage({
				defaultMessage: 'Verkkolasku',
				description: 'Label for invoicing method web invoice.',
			})
		case InvoicingMethod.PAPAER_INVOICE:
			return intl.formatMessage({
				defaultMessage: 'Paperilasku',
				description: 'Label for invoicing method paper invoice.',
			})
		default:
			return ''
	}
}

export const directionMessage = (
	intl: IntlShape,
	direction: Direction | string,
) => {
	switch (direction) {
		case Direction.INVOICE:
			return intl.formatMessage({
				defaultMessage: 'Lähetys',
				description: 'Label for direction invoice sent.',
			})
		default:
			return ''
	}
}

export const taxPeriodLengthMessage = (
	intl: IntlShape,
	taxPeriodLength: TaxPeriodLength | string,
) => {
	switch (taxPeriodLength) {
		case TaxPeriodLength.MONTH:
			return intl.formatMessage({
				defaultMessage: 'Kuukausi',
				description: 'Label for tax period length of: month.',
			})
		case TaxPeriodLength.QUARTER:
			return intl.formatMessage({
				defaultMessage: 'Neljännesvuosi',
				description: 'Label for tax period length of: quarter.',
			})
		case TaxPeriodLength.YEAR:
			return intl.formatMessage({
				defaultMessage: 'Vuosi',
				description: 'Label for tax period length of: year.',
			})
		default:
			return ''
	}
}

export const invoiceStatusMessage = (
	intl: IntlShape,
	invoiceStatus: InvoiceStatus | string,
) => {
	switch (invoiceStatus) {
		case InvoiceStatus.SAVED:
			return intl.formatMessage({
				defaultMessage: 'Tallennettu',
				description: 'Label for invoice status: saved',
			})
		case InvoiceStatus.CREATED:
			return intl.formatMessage({
				defaultMessage: 'Luotu',
				description: 'Label for invoice status: created',
			})
		case InvoiceStatus.SENT:
			return intl.formatMessage({
				defaultMessage: 'Lähetetty',
				description: 'Label for invoice status: sent',
			})
		case InvoiceStatus.PAID:
			return intl.formatMessage({
				defaultMessage: 'Maksettu',
				description: 'Label for invoice status: paid',
			})
		case InvoiceStatus.VOID:
			return intl.formatMessage({
				defaultMessage: 'Mitätöity',
				description: 'Label for invoice status: void',
			})
		case InvoiceStatus.VOID_BY_UPDATION:
			return intl.formatMessage({
				defaultMessage: 'Mitätöity päivityksen takia',
				description: 'Label for invoice status: void by updation',
			})
		default:
			return invoiceStatus
	}
}

export const countryCodeMessage = (
	intl: IntlShape,
	countryCode: CountryCode,
) => {
	switch (countryCode) {
		case CountryCode.FI:
			return intl.formatMessage({
				defaultMessage: 'Suomi',
				description: 'Label for country code: FIN',
			})
		default:
			return ''
	}
}

export const InvoicingMonthMessage = (
	intl: IntlShape,
	invoicingMonth: InvoicingMonth | string,
) => {
	switch (invoicingMonth) {
		case InvoicingMonth.JANUARY:
			return intl.formatMessage({
				defaultMessage: 'Tammikuu - Joulukuu',
				description: 'Label for invoicing month: January - December',
			})
		case InvoicingMonth.FEBRUARY:
			return intl.formatMessage({
				defaultMessage: 'Helmikuu - Tammikuu',
				description: 'Label for invoicing month: February - January',
			})
		case InvoicingMonth.MARCH:
			return intl.formatMessage({
				defaultMessage: 'Maaliskuu - Helmikuu',
				description: 'Label for invoicing month: March - February',
			})
		case InvoicingMonth.APRIL:
			return intl.formatMessage({
				defaultMessage: 'Huhtikuu - Maaliskuu',
				description: 'Label for invoicing month: April - March',
			})
		case InvoicingMonth.MAY:
			return intl.formatMessage({
				defaultMessage: 'Toukokuu - Huhtikuu',
				description: 'Label for invoicing month: May - April',
			})
		case InvoicingMonth.JUNE:
			return intl.formatMessage({
				defaultMessage: 'Kesäkuu - Toukokuu',
				description: 'Label for invoicing month: June - May',
			})
		case InvoicingMonth.JULY:
			return intl.formatMessage({
				defaultMessage: 'Heinäkuu - Kesäkuu',
				description: 'Label for invoicing month: July - June',
			})
		case InvoicingMonth.AUGUST:
			return intl.formatMessage({
				defaultMessage: 'Elokuu - Heinäkuu',
				description: 'Label for invoicing month: August - July',
			})
		case InvoicingMonth.SEPTEMBER:
			return intl.formatMessage({
				defaultMessage: 'Syyskuu - Elokuu',
				description: 'Label for invoicing month: September - August',
			})
		case InvoicingMonth.OCTOBER:
			return intl.formatMessage({
				defaultMessage: 'Lokakuu - Syyskuu',
				description: 'Label for invoicing month: October - September',
			})
		case InvoicingMonth.NOVEMBER:
			return intl.formatMessage({
				defaultMessage: 'Marraskuu - Lokakuu',
				description: 'Label for invoicing month: November - October',
			})
		case InvoicingMonth.DECEMBER:
			return intl.formatMessage({
				defaultMessage: 'Joulukuu - Marraskuu',
				description: 'Label for invoicing month: December - November',
			})
		default:
			return ''
	}
}

export const UPDATE_INVOICES_REMINDER_MESSAGE = (intl: IntlShape) =>
	intl.formatMessage({
		defaultMessage:
			'Muutokset voivat vaikuttaa laskuihin, muista käydä päivittämässä laskut. Laskujen päivitys löytyy laskut näkymän toimintovalikon alta',
		description:
			'Changes may affect invoices, remember to update them. Invoice updates can be found under the action menu in the invoices view.',
	})

export const AccountsLedgerGroupByMessage = (
	intl: IntlShape,
	groupBy: string,
) => {
	switch (groupBy) {
		case AccountsLedgerGroupBy.APARTMENT:
			return intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Label for accounts ledger group by: Apartment',
			})
		case AccountsLedgerGroupBy.CONTRACT:
			return intl.formatMessage({
				defaultMessage: 'Sopimus',
				description: 'Label for accounts ledger group by: Contract',
			})
		case AccountsLedgerGroupBy.PARTY:
			return intl.formatMessage({
				defaultMessage: 'Osapuoli',
				description: 'Label for accounts ledger group by: Party',
			})
		default:
			return ''
	}
}

export const AccountsLedgerViewMessage = (intl: IntlShape, view: string) => {
	switch (view) {
		case AccountsLedgerView.ACTUALS:
			return intl.formatMessage({
				defaultMessage: 'Toteuma',
				description: 'Label for accounts ledger group by: Actuals',
			})
		case AccountsLedgerView.FORECAST:
			return intl.formatMessage({
				defaultMessage: 'Tavoitteet',
				description: 'Label for accounts ledger group by: Forecast',
			})
		default:
			return ''
	}
}

export const meterLocationMessage = (
	intl: IntlShape,
	location: MeterLocation,
) => {
	switch (location) {
		case MeterLocation.APARTMENT:
			return intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Label for meter location: Apartment',
			})
		case MeterLocation.COMPANY:
			return intl.formatMessage({
				defaultMessage: 'Kohde',
				description: 'Label for meter location: Company',
			})
		default:
			return ''
	}
}

export const meterTypeMessage = (intl: IntlShape, type: MeterTypeKey) => {
	switch (type) {
		case 'WATER':
			return intl.formatMessage({
				defaultMessage: 'Vesi',
				description: 'Label for meter type: Water',
			})
		case 'ELECTRICITY':
			return intl.formatMessage({
				defaultMessage: 'Sähkö',
				description: 'Label for meter type: Electricity',
			})
		case 'HEATING':
			return intl.formatMessage({
				defaultMessage: 'Lämmitys',
				description: 'Label for meter type: Heating',
			})
		default:
			return ''
	}
}

export const readingMethodMessage = (
	intl: IntlShape,
	readingMethod: ReadingMethodKey,
) => {
	switch (readingMethod) {
		case 'MANUAL_COLD':
			return intl.formatMessage({
				defaultMessage: 'Manuaalinen kylmä',
				description: 'Label for meter reading method: Manual cold',
			})
		case 'MANUAL_HOT':
			return intl.formatMessage({
				defaultMessage: 'Manuaalinen lämmin',
				description: 'Label for meter reading method: Manual hot',
			})
		case 'MANUAL_COLD_HOT':
			return intl.formatMessage({
				defaultMessage: 'Manuaalinen kylmä ja lämmin',
				description: 'Label for meter reading method: Manual cold and hot',
			})
		case 'REMOTE_COLD':
			return intl.formatMessage({
				defaultMessage: 'Etäluettava kylmä',
				description: 'Label for meter reading method: Remote cold',
			})
		case 'REMOTE_HOT':
			return intl.formatMessage({
				defaultMessage: 'Etäluettava lämmin',
				description: 'Label for meter reading method: Remote hot',
			})
		case 'REMOTE_COLD_HOT':
			return intl.formatMessage({
				defaultMessage: 'Etäluettava kylmä ja lämmin',
				description: 'Label for meter reading method: Remote cold and hot',
			})
		case 'MANUAL_DAY_NIGH':
			return intl.formatMessage({
				defaultMessage: 'Manuaalinen päivä-yö',
				description: 'Label for meter reading method: Manual day-night',
			})
		case 'MANUAL':
			return intl.formatMessage({
				defaultMessage: 'Manuaalinen',
				description: 'Label for meter reading method: Manual',
			})
		case 'REMOTE':
			return intl.formatMessage({
				defaultMessage: 'REMOTE',
				description: 'Label for meter reading method: Remote',
			})
		default:
			return ''
	}
}

export const readingStatusMessage = (
	intl: IntlShape,
	readingStatus: ReadingStatus,
) => {
	switch (readingStatus) {
		case ReadingStatus.INITIAL_READING:
			return intl.formatMessage({
				defaultMessage: 'Alkulukema',
				description: 'Label for meter reading status: Initial reading',
			})
		case ReadingStatus.BASE_READING:
			return intl.formatMessage({
				defaultMessage: 'Peruslukema',
				description: 'Label for meter reading status: Base reading',
			})
		case ReadingStatus.FINAL_READING:
			return intl.formatMessage({
				defaultMessage: 'Loppulukema',
				description: 'Label for meter reading status: Final reading',
			})

		default:
			return ''
	}
}
