import { useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	SelectField,
	TextField,
	TextFieldTypes,
} from 'react-md'
import type { Action } from 'ui/components/ViiluFullPageDialog'
import ViiluFullPageDialog from 'ui/components/ViiluFullPageDialog'
import { CANCEL_BUTTON_LABEL, SAVE_BUTTON_LABEL } from 'ui/messages'
import type { MeterReadingForm, RegistryMeter } from '../types'
import { Fragment, useState } from 'react'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import DatePickerISO from 'ui/components/DatePickerISO'
import { meterReadingFormHeaders } from '../constants'
import { getOptions } from '../utils'
import type { ApartmentSelectOptions } from '../../types'
import styled from 'styled-components'
import { StandardDialogContent } from 'ui/StyledComponents/BasicComponents'
import { _createMeterReading } from 'state/finance-meter-registry-actions'

const FormContainer = styled.div`
	margin-left: 24px;
	margin-right: 24px;
	padding-bottom: 64;
`

type Props = {
	onHide: () => void
	refresh: () => void
	selectedCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
	registryMeters: RegistryMeter[]
}

const MeterReadingDialog = ({
	onHide,
	refresh,
	selectedCompanyUUID,
	apartmentOptions,
	registryMeters,
}: Props) => {
	const intl = useIntl()
	const [processing, setProcessing] = useState(false)

	const [formData, setFormData] = useState<MeterReadingForm>({
		companyUUID: selectedCompanyUUID,
	} as MeterReadingForm)

	const createMeterReading = async () => {
		setProcessing(true)

		//TODO: Validate formData

		try {
			const result = await _createMeterReading(formData)
			if (!result?.ok) {
				throw new Error('Response is not ok.')
			}
			refresh()
			onHide()
		} catch (error) {
			alert('Something went wrong.')
			setProcessing(false)
		}
	}

	const handleSelectChange = (value: ListValue, field: string) => {
		/*TODO: Reset formData if needed
		if (field === 'XXX') {
			setFormData((prevData) => ({
				...prevData,
                [field]: value,
                DO XXX
			}))
		} else*/
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const handleInputChange = (
		value: string | number,
		field: string,
		type: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: type === 'number' ? Number(value) : value,
		}))
	}

	const renderForm = () => {
		return (
			<FormContainer>
				{meterReadingFormHeaders.map((header) => {
					const { title, key, type, show } = header
					const disabled = false //!editable &&uuid
					if (!show) return null
					else if (type === 'date') {
						return (
							<DatePickerISO
								style={{ width: '100%' }}
								key={key}
								id={key}
								label={title(intl)}
								value={formData[key] as string}
								onChange={(value) => handleInputChange(value, key, type)}
								disabled={disabled}
							/>
						)
					} else if (type.toLowerCase().includes('options')) {
						return (
							<Fragment key={key}>
								<p className="text-subtle">{title(intl)}</p>
								<SelectField
									id={key}
									placeholder={title(intl)}
									value={formData[key] as string}
									onChange={(value) => handleSelectChange(value, key)}
									menuItems={getOptions(type, {
										intl,
										apartmentOptions,
										registryMeters,
									})}
									position={SelectField.Positions.BELOW}
									simplifiedMenu={false}
									listHeightRestricted
									disabled={disabled}
									style={{
										width: '100%',
										background: '#fafafa',
										marginBottom: '10px',
									}}
								/>
							</Fragment>
						)
					} else {
						return (
							<TextField
								key={key}
								id={key}
								label={title(intl)}
								floating
								lineDirection="center"
								fullWidth
								value={formData[key] as typeof type}
								onChange={(value) => handleInputChange(value, key, type)}
								disabled={disabled}
								type={(type as TextFieldTypes) || undefined}
							/>
						)
					}
				})}
			</FormContainer>
		)
	}

	const title = intl.formatMessage({
		defaultMessage: 'Lisää mittarilukema',
		description: 'Title for the meter reading dialog: Add meter reading',
	})

	const actions: Action[] = [
		{
			id: 'dialog-cancel',
			children: CANCEL_BUTTON_LABEL(intl),
			onClick: onHide,
		},
		{
			id: 'dialog-save',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				SAVE_BUTTON_LABEL(intl)
			),
			onClick: () => createMeterReading(),
		},
	]

	return (
		<ViiluFullPageDialog
			id="meter-reading-dialog"
			title={title}
			visible
			actions={actions}
			onHide={onHide}
			focusOnMount={false}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button icon onClick={onHide}>
					close
				</Button>
			}
		>
			<StandardDialogContent>{renderForm()}</StandardDialogContent>
		</ViiluFullPageDialog>
	)
}

export default MeterReadingDialog
