import { useIntl } from 'react-intl'
import {
	Button,
	CircularProgress,
	SelectField,
	TextField,
	TextFieldTypes,
} from 'react-md'
import styled from 'styled-components'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'
import {
	registryMeterApartmentsHeaders,
	registryMeterFormHeaders,
} from './constants'
import { Fragment, useState } from 'react'
import type { RegistryMeterForm } from './types'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import DatePickerISO from 'ui/components/DatePickerISO'
import { StandardDialogContent } from 'ui/StyledComponents/BasicComponents'
import type { ApartmentSelectOptions } from '../types'
import { getOptions } from './utils'
import { CANCEL_BUTTON_LABEL, SAVE_BUTTON_LABEL } from 'ui/messages'
import { _createMeter } from 'state/finance-meter-registry-actions'

const FormContainer = styled.div`
	margin-left: 24px;
	margin-right: 24px;
	padding-bottom: 64;
`

type Props = {
	onHide: () => void
	refresh: () => void
	selectedCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
	addMetersToApartments?: boolean
}

const MeterRegistryDialog = ({
	onHide,
	refresh,
	selectedCompanyUUID,
	apartmentOptions,
	addMetersToApartments = false,
}: Props) => {
	const intl = useIntl()
	const [processing, setProcessing] = useState(false)

	const [formData, setFormData] = useState<RegistryMeterForm>({
		companyUUID: selectedCompanyUUID,
		apartmentUUID: null,
		apartmentIdentifier: null,
		meter: '',
		location: '',
		type: 'WATER',
		readingMethod: '',
		additionalInfo: '',
	})

	const createMeter = async () => {
		setProcessing(true)

		//TODO: Validate formData

		try {
			const result = await _createMeter(formData)
			if (!result?.ok) {
				throw new Error('Response is not ok.')
			}
			refresh()
			onHide()
		} catch (error) {
			alert('Something went wrong.')
			setProcessing(false)
		}
	}

	const handleSelectChange = (value: ListValue, field: string) => {
		/*TODO: Reset formData if needed
		if (field === 'XXX') {
			setFormData((prevData) => ({
				...prevData,
                [field]: value,
                DO XXX
			}))
		} else*/
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const handleInputChange = (value: string | number, field: string) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const renderForm = () => {
		const headers = addMetersToApartments
			? registryMeterApartmentsHeaders
			: registryMeterFormHeaders
		return (
			<FormContainer>
				{headers.map((header) => {
					const {
						title,
						key,
						type,
						required,
						show,
						//TODO: editable,
						optionsFormKey,
						showKey,
						showValue,
					} = header
					const disabled = false //!editable &&uuid
					if (!show) return null

					if (showKey && showValue) {
						const showKeyValue = formData[showKey]
						if (showKeyValue !== showValue) return null
					}

					if (type.toLowerCase().includes('options')) {
						return (
							<Fragment key={key}>
								<p className="text-subtle">{title(intl)}</p>
								<SelectField
									id={key}
									placeholder={title(intl)}
									value={formData[key]}
									onChange={(value) => handleSelectChange(value, key)}
									menuItems={getOptions(type, {
										intl,
										apartmentOptions,
										formData,
										formKey: optionsFormKey,
									})}
									position={SelectField.Positions.BELOW}
									simplifiedMenu={false}
									listHeightRestricted
									disabled={disabled}
									style={{
										width: '100%',
										background: '#fafafa',
										marginBottom: '10px',
									}}
									required={required}
								/>
							</Fragment>
						)
					} else if (type === 'date') {
						return (
							<DatePickerISO
								style={{ width: '100%' }}
								key={key}
								id={key}
								label={title(intl)}
								value={formData[key]}
								onChange={(value) => handleInputChange(value, key)}
								disabled={disabled}
							/>
						)
					} else {
						return (
							<TextField
								key={key}
								id={key}
								label={title(intl)}
								floating
								lineDirection="center"
								fullWidth
								value={formData[key]}
								onChange={(value) => handleInputChange(value, key)}
								required={required}
								disabled={disabled}
								type={(type as TextFieldTypes) || undefined}
							/>
						)
					}
				})}
			</FormContainer>
		)
	}

	const title = intl.formatMessage({
		defaultMessage: 'Luo mittari',
		description: 'Title for the meter registry dialog: Add meter',
	})

	const addMetersToApartmentsTitle = intl.formatMessage({
		defaultMessage: 'Luo mittarit huoneistoille',
		description:
			'Title for the meter registry dialog: Add meters to apartments',
	})

	const actions: Action[] = [
		{
			id: 'dialog-cancel',
			children: CANCEL_BUTTON_LABEL(intl),
			onClick: onHide,
		},
		{
			id: 'dialog-save',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				SAVE_BUTTON_LABEL(intl)
			),
			onClick: () => createMeter(),
		},
	]

	return (
		<ViiluFullPageDialog
			id="meter-registry-dialog"
			title={addMetersToApartments ? addMetersToApartmentsTitle : title}
			visible
			onHide={onHide}
			focusOnMount={false}
			actions={actions}
			toolbarStyle={{
				background: 'var(--color-secondary-dark)',
			}}
			nav={
				<Button icon onClick={onHide}>
					close
				</Button>
			}
		>
			<StandardDialogContent>{renderForm()}</StandardDialogContent>
		</ViiluFullPageDialog>
	)
}

export default MeterRegistryDialog
