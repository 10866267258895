import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DropdownField from 'ui/components/DropdownField'
import type { MeterRegistryFilters as Filters } from './types'
import type { ApartmentSelectOptions } from '../types'
import { getOptions } from './utils'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin: 16px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: Filters
	setFilters: React.Dispatch<React.SetStateAction<Filters>>
	apartmentOptions: ApartmentSelectOptions[]
}

const MeterRegistryFilters = ({
	filters,
	setFilters,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()

	const filterFields = [
		{
			label: intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Label of apartment',
			}),
			value: filters.apartmentUUID,
			options: apartmentOptions,
			_onOptionSelect: (value) =>
				setFilters((prevData) => ({
					...prevData,
					apartmentUUID: value,
				})),
			hasSelectAll: true,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Label of type',
			}),
			_onOptionSelect: (value) => {
				setFilters((prevData) => ({
					...prevData,
					type: value,
				}))
			},
			value: filters.type,
			options: getOptions('typeOptions', { intl: intl }),
			hasSelectAll: true,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
	]

	const renderFilterSelectField = (filterProps) => {
		if (filterProps.type === 'dropdown' && Array.isArray(filterProps.options)) {
			return (
				<Filter key={filterProps.label}>
					<DropdownField {...filterProps} />
				</Filter>
			)
		}
	}

	return <Container>{filterFields.map(renderFilterSelectField)}</Container>
}

export default MeterRegistryFilters
