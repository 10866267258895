import { useState } from 'react'
import type { MeterWithReadings, MeterWithReadingsKey } from '../types'
import {
	TableRow,
	TableColumn,
	FontIcon,
	DataTable,
	TableHeader,
	TableBody,
	ListItem,
} from 'react-md'
import OverflowGradient from 'ui/components/OverflowGradient'
import { TableContainer } from 'ui/StyledComponents/Tables'
import { getMeterWithReadingsRowValue } from '../utils'
import { useIntl } from 'react-intl'
import naturalSort from 'javascript-natural-sort'
import { meterWithReadingsHeaders } from '../constants'
import MeterWithReadingsTableRow from './MeterWithReadingsTableRow'
import type { ApartmentSelectOptions } from '../../types'

type Props = {
	metersWithReadings: MeterWithReadings[]
	apartmentOptions: ApartmentSelectOptions[]
}

const MetersWithReadingsTable = ({
	metersWithReadings,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()

	const [sort, setSort] = useState<{
		key: MeterWithReadingsKey
		type: string | null
		order: string
	}>({
		key: 'meter',
		type: null,
		order: 'asc',
	})

	const changeSort = (key: MeterWithReadingsKey, type?: string) => {
		if (sort.key === key) {
			setSort((prevData) => ({
				...prevData,
				order: sort.order === 'asc' ? 'desc' : 'asc',
			}))
		} else {
			setSort({
				key: key,
				type: type || null,
				order: 'asc',
			})
		}
	}

	if (!metersWithReadings || !metersWithReadings.length) {
		return (
			<p style={{ marginLeft: '1.5rem' }}>
				{intl.formatMessage({
					defaultMessage: 'Mittarilukemia ei löytynyt.',
					description: 'Message for empty meters with readings.',
				})}
			</p>
		)
	}

	const renderTableHeaders = () => {
		return (
			<TableRow>
				{meterWithReadingsHeaders
					.filter((h) => h.show)
					.map((header) => (
						<TableColumn
							key={header.key}
							onClick={() =>
								header.sortable && changeSort(header.key, header.type)
							}
						>
							<div
								style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
							>
								<p className="text-strong text-subtle">{header.title(intl)}</p>
								{sort.key === header.key && (
									<FontIcon style={{ fontSize: 12 }}>
										{sort.order === 'asc' ? 'arrow_upward' : 'arrow_downward'}
									</FontIcon>
								)}
							</div>
						</TableColumn>
					))}
				<TableColumn key={'children-header'}></TableColumn>
			</TableRow>
		)
	}

	return (
		<TableContainer>
			<TableContainer>
				<DataTable
					plain
					style={{
						background: 'white',
						maxHeight: 'calc(100vh - 205px)',
					}}
				>
					<TableHeader
						style={{
							position: 'sticky',
							top: 0,
							zIndex: 1,
							background: 'white',
						}}
					>
						{renderTableHeaders()}
					</TableHeader>
					<TableBody>
						{metersWithReadings
							.sort((a, b) => {
								const aValue =
									sort.type && sort.type !== 'boolean'
										? getMeterWithReadingsRowValue(
												a,
												sort.key,
												intl,
												apartmentOptions,
												sort.type,
										  )
										: a[sort.key]

								const bValue =
									sort.type && sort.type !== 'boolean'
										? getMeterWithReadingsRowValue(
												b,
												sort.key,
												intl,
												apartmentOptions,
												sort.type,
										  )
										: b[sort.key]

								return sort.order === 'asc'
									? naturalSort(aValue, bValue)
									: naturalSort(bValue, aValue)
							})
							.map((meterWithReadings) => (
								<MeterWithReadingsTableRow
									key={meterWithReadings.uuid}
									meterWithReadings={meterWithReadings}
									menuItems={[
										<ListItem
											key={1}
											primaryText={intl.formatMessage({
												defaultMessage: 'Poista ',
												description: 'Delete',
											})}
											leftIcon={<FontIcon>delete</FontIcon>}
											onClick={() => {}}
											className="md-divider-border md-divider-border--right"
										/>,
									]}
									children={null}
									processing={false}
									apartmentOptions={apartmentOptions}
								/>
							))
							.concat(
								<tr
									key="empty-row"
									className="full-width"
									style={{ height: 100, background: 'white' }}
								></tr>,
							)}
					</TableBody>
					<OverflowGradient style={{ bottom: 40 }} />
				</DataTable>
			</TableContainer>
		</TableContainer>
	)
}

export default MetersWithReadingsTable
