import type { IntlShape } from 'react-intl'
import type { ApartmentSelectOptions } from '../types'
import type {
	MeterReading,
	MeterWithReadings,
	RegistryMeter,
	RegistryMeterForm,
} from './types'
import moment from 'moment'
import {
	MeterLocation,
	MeterType,
	MeterTypeKey,
	ReadingMethodKey,
	ReadingStatus,
} from '../enum'
import {
	meterLocationMessage,
	meterTypeMessage,
	readingMethodMessage,
	readingStatusMessage,
} from '../utils/messages'
import { YES_NO_MESSAGE } from 'ui/messages'

export const getMeterRegistryRowValue = (
	registryMeter: RegistryMeter,
	key: string,
	intl: IntlShape,
	apartmentOptions: ApartmentSelectOptions[],
	type?: string,
) => {
	const value = registryMeter[key]

	switch (type) {
		case 'date':
			const parsedDate = moment(value)
			if (!parsedDate.isValid()) return ''
			return parsedDate.format('DD.MM.YYYY')

		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.value === value)?.label ||
				registryMeter.apartmentIdentifier ||
				'-'
			)

		case 'meterType':
			return meterTypeMessage(intl, value as MeterTypeKey)

		case 'readingMethod':
			return readingMethodMessage(intl, value as ReadingMethodKey)

		default:
			return value
	}
}

export const getMeterWithReadingsRowValue = (
	meterWithReadings: MeterWithReadings,
	key: string,
	intl: IntlShape,
	apartmentOptions: ApartmentSelectOptions[],
	type?: string,
) => {
	const value = meterWithReadings[key]
	switch (type) {
		case 'date':
			if (!value) return ''
			const parsedDate = moment(value)
			return parsedDate.format('DD.MM.YYYY')
		case 'meterType':
			const readingMethod = readingMethodMessage(
				intl,
				meterWithReadings.readingMethod as ReadingMethodKey,
			)
			return (
				meterTypeMessage(intl, value as MeterTypeKey) + ` (${readingMethod})`
			)
		case 'apartmentOptions':
			return (
				apartmentOptions.find((a) => a.value === value)?.label ||
				meterWithReadings.apartmentIdentifier ||
				'-'
			)
		default:
			return value
	}
}

export const getMeterReadingRowValue = (
	meterReading: MeterReading,
	key: string,
	intl: IntlShape,
	type?: string,
) => {
	const value = meterReading[key]
	switch (type) {
		case 'date':
			if (!value) return ''
			const parsedDate = moment(value)
			return parsedDate.format('DD.MM.YYYY')
		case 'statusOptions':
			return readingStatusMessage(intl, value)
		case 'meter':
			return meterReading.meter[key]
		default:
			return value
	}
}

type GetOptionsContext = {
	intl: IntlShape
	apartmentOptions?: ApartmentSelectOptions[]
	formData?: RegistryMeterForm
	formKey?: string
	registryMeters?: RegistryMeter[]
}

export const getOptions = (type: string, context: GetOptionsContext) => {
	const {
		intl,
		apartmentOptions = [],
		formData,
		formKey,
		registryMeters = [],
	} = context
	switch (type) {
		case 'apartmentOptions':
			return apartmentOptions
		case 'locationOptions':
			return Object.values(MeterLocation).map((value) => ({
				label: meterLocationMessage(intl, value),
				value: value,
			}))
		case 'typeOptions':
			return (Object.keys(MeterType) as MeterTypeKey[]).map((key) => ({
				label: meterTypeMessage(intl, key),
				value: key,
			}))

		case 'readingMethodOptions':
			const selectedType = formData?.[formKey as MeterTypeKey]
			if (!selectedType || !MeterType[selectedType]?.MeterReadingMethod)
				return []

			return Object.entries(MeterType[selectedType].MeterReadingMethod).map(
				([value]) => ({
					label: readingMethodMessage(intl, value as ReadingMethodKey),
					value,
				}),
			)
		case 'yesNoOptions':
			return [
				{ label: YES_NO_MESSAGE(intl, true), value: true },
				{ label: YES_NO_MESSAGE(intl, false), value: false },
			]
		case 'statusOptions':
			return Object.values(ReadingStatus).map((value) => ({
				label: readingStatusMessage(intl, value),
				value: value,
			}))
		case 'meterOptions':
			return registryMeters.map((meter) => ({
				label: meter.meter,
				value: meter.uuid,
			}))

		default:
			return []
	}
}
