import { useState } from 'react'
import MeterRegistryDialog from './MeterRegistryDialog'
import FloatingActionBtnMenu from 'ui/components/FloatingActionBtn/FloatingActionBtnMenu'
import { useIntl } from 'react-intl'
import MeterRegistryFilters from './MeterRegistryFilters'
import MeterRegistryTable from './MeterRegistryTable'
import type { MeterRegistryFilters as Filters } from './types'
import type { ApartmentSelectOptions } from '../types'
import styled from 'styled-components'
import useMeters from './useMeters'

const Container = styled.div``

type Props = {
	selectedCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
}

const MeterRegistryView = ({
	selectedCompanyUUID,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()

	const [filters, setFilters] = useState<Filters>({
		companyUUID: selectedCompanyUUID,
		apartmentUUID: null,
		type: null,
	})

	const { data: registryMeters, refresh } = useMeters(filters)

	const [addMeterRegistryDialog, setAddMeterRegistryDialog] = useState(false)
	const [addMetersToApartments, setAddMetersToApartments] = useState(false)

	const createMetersToApartments = () => {
		setAddMeterRegistryDialog(true)
		setAddMetersToApartments(true)
	}

	const hideDialog = () => {
		setAddMeterRegistryDialog(false)
		setAddMetersToApartments(false)
		refresh()
	}

	const renderFAB = () => {
		const buttons = [
			{
				key: 'add_meter',
				secondary: true,
				iconName: 'add',
				label: intl.formatMessage({
					defaultMessage: 'Luo mittari',
					description: 'Label for the create a meter registry button.',
				}),
				action: () => setAddMeterRegistryDialog(true),
			},
			{
				key: 'add_apartment_meters',
				secondary: true,
				iconName: 'add',
				label: intl.formatMessage({
					defaultMessage: 'Luo mittarit huoneistoille',
					description: 'Label for the create a meter registry button.',
				}),
				action: () => createMetersToApartments(),
			},
		]

		return (
			<FloatingActionBtnMenu
				iconName="add"
				text={intl.formatMessage({
					defaultMessage: 'Lisää mittareita',
					description: 'Label for the .',
				})}
				buttons={buttons}
			/>
		)
	}

	return (
		<Container>
			<MeterRegistryFilters
				filters={filters}
				setFilters={setFilters}
				apartmentOptions={apartmentOptions}
			/>
			<MeterRegistryTable
				registryMeters={registryMeters}
				apartmentOptions={apartmentOptions}
			/>
			{renderFAB()}
			{addMeterRegistryDialog && (
				<MeterRegistryDialog
					onHide={hideDialog}
					refresh={refresh}
					selectedCompanyUUID={selectedCompanyUUID}
					apartmentOptions={apartmentOptions}
					addMetersToApartments={addMetersToApartments}
				/>
			)}
		</Container>
	)
}

export default MeterRegistryView
