import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import type { MeterWithReadings } from '../types'
import { meterWithReadingsHeaders } from '../constants'
import { getMeterWithReadingsRowValue } from '../utils'
import type { ApartmentSelectOptions } from '../../types'

type Props = {
	meterWithReadings: MeterWithReadings
	menuItems: any[] | null
	children: any
	processing: any
	apartmentOptions: ApartmentSelectOptions[]
}

const MeterWithReadingsTableRow = ({
	meterWithReadings,
	menuItems,
	children,
	processing,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(
			push(window.location.pathname + '?ruuid=' + meterWithReadings.uuid),
		)
	}

	const renderTableColumns = () => {
		return meterWithReadingsHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={handleOnClick} key={key}>
						<p>
							{getMeterWithReadingsRowValue(
								meterWithReadings,
								key,
								intl,
								apartmentOptions,
								type,
							)}
						</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={meterWithReadings.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default MeterWithReadingsTableRow
