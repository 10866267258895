import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import { MenuButton, TableColumn, TableRow, CircularProgress } from 'react-md'
import { useDispatch } from 'react-redux'
import type { MeterReading } from '../types'
import { meterReadingHeaders } from '../constants'
import { getMeterReadingRowValue } from '../utils'

type Props = {
	meterReading: MeterReading
	menuItems: any[] | null
	children: any
	processing: any
}

const MeterReadingTableRow = ({
	meterReading,
	menuItems,
	children,
	processing,
}: Props) => {
	const intl = useIntl()
	const dispatch = useDispatch()
	const appendedMenuItems = menuItems ? [...menuItems] : null

	const menu = !appendedMenuItems ? null : (
		<MenuButton
			id={'menu-button'}
			icon
			menuItems={appendedMenuItems}
			centered
			anchor={{
				x: MenuButton.HorizontalAnchors['CENTER'],
				y: MenuButton.VerticalAnchors['CENTER'],
			}}
		>
			more_vert
		</MenuButton>
	)

	const handleOnClick = () => {
		dispatch(push(window.location.pathname + '?ruuid=' + meterReading.uuid))
	}

	const renderTableColumns = () => {
		return meterReadingHeaders
			.filter((h) => h.show)
			.map((header) => {
				const { key, type } = header
				return (
					<TableColumn onClick={handleOnClick} key={key}>
						<p>{getMeterReadingRowValue(meterReading, key, intl, type)}</p>
					</TableColumn>
				)
			})
	}

	return (
		<TableRow key={meterReading.uuid}>
			{renderTableColumns()}
			<TableColumn key={'children'}>
				<div>
					{processing ? (
						<CircularProgress id="progress" style={{ marginTop: 0 }} />
					) : (
						menu
					)}
					{children}
				</div>
			</TableColumn>
		</TableRow>
	)
}

export default MeterReadingTableRow
