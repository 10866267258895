import { useCallback, useEffect, useState } from 'react'
import { _getMeters } from 'state/finance-meter-registry-actions'
import type { RegistryMeter, MeterRegistryFilters } from './types'

type State = {
	loading: boolean
	data: RegistryMeter[]
}

const useMeters = (filters: MeterRegistryFilters) => {
	const [state, setState] = useState<State>({ loading: true, data: [] })

	const doQuery = useCallback(async () => {
		if (!filters.companyUUID) {
			setState({ loading: false, data: [] })
			return
		}

		setState((prev) => ({ ...prev, loading: true }))
		let data: RegistryMeter[] = []
		try {
			const response = await _getMeters(filters)
			if (response?.ok) {
				const result = await response.json()
				if (result.meters && Array.isArray(result.meters)) {
					data = result.meters
				}
			} else {
				throw new Error('Response is not ok.')
			}
		} catch (error) {
			console.error(error)
		}
		setState({
			loading: false,
			data,
		})
	}, [filters])

	useEffect(() => {
		doQuery()
	}, [doQuery])

	return { ...state, refresh: doQuery }
}

export default useMeters
