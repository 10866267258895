import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import FloatingActionBtnMenu from 'ui/components/FloatingActionBtn/FloatingActionBtnMenu'
import MeterReadingDialog from './MeterReadingDialog'
import type { ApartmentSelectOptions } from '../../types'
import MeterReadingFilters from './MeterReadingFilters'
import type { MeterReadingFilters as Filters } from '../types'
import MeterReadingTable from './MeterReadingTable'
import styled from 'styled-components'
import useMeterReadings from './useMeterReadings'
import useMeters from '../useMeters'

const Container = styled.div``

type Props = {
	selectedCompanyUUID: string
	apartmentOptions: ApartmentSelectOptions[]
}

const MeterReadingsView = ({
	selectedCompanyUUID,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()

	const meterFilters = useMemo(
		() => ({
			companyUUID: selectedCompanyUUID,
			apartmentUUID: null,
			type: null,
		}),
		[selectedCompanyUUID],
	)

	const { data: registryMeters } = useMeters(meterFilters)

	const [filters, setFilters] = useState<Filters>({
		companyUUID: selectedCompanyUUID,
		apartmentUUID: null,
		startDate: null,
		endDate: null,
	})

	const { data: meterReadings, refresh } = useMeterReadings(filters)

	const [addMeterReadingDialog, setAddMeterReadingDialog] = useState(false)

	const renderFAB = () => {
		const buttons = [
			{
				key: 'meter_reading',
				secondary: true,
				iconName: 'add',
				label: intl.formatMessage({
					defaultMessage: 'Lisää mittarilukema',
					description: 'Label for the create a meter reading button.',
				}),
				action: () => setAddMeterReadingDialog(true),
			},
		]

		return (
			<FloatingActionBtnMenu
				iconName="add"
				text={intl.formatMessage({
					defaultMessage: 'Ilmoita mittarilukemia',
					description: 'Label for the .',
				})}
				buttons={buttons}
			/>
		)
	}

	return (
		<Container>
			<MeterReadingFilters
				filters={filters}
				setFilters={setFilters}
				apartmentOptions={apartmentOptions}
			/>
			<MeterReadingTable meterReadings={meterReadings} />
			{addMeterReadingDialog && (
				<MeterReadingDialog
					onHide={() => setAddMeterReadingDialog(false)}
					refresh={refresh}
					apartmentOptions={apartmentOptions}
					registryMeters={registryMeters}
					selectedCompanyUUID={selectedCompanyUUID}
				/>
			)}
			{renderFAB()}
		</Container>
	)
}

export default MeterReadingsView
