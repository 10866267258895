import type {
	MeterReadingFilters,
	MeterReadingForm,
	MeterRegistryFilters,
	MetersWithReadingsFilters,
	RegistryMeterForm,
} from 'ui/ManagerFinance/MeterRegistry/types'
import { callApi } from './api/finance-api'
import { generateQueryParams } from 'util/queryUtils'

//Meters
export const _getMeters = async (meterFilters: MeterRegistryFilters) => {
	return callApi(`/v1/meters${generateQueryParams(meterFilters)}`, {
		method: 'GET',
	})
}

export const _getMeter = async (meterUUID: string) => {
	return callApi(`/v1/meter/${meterUUID}`, {
		method: 'GET',
	})
}

export const _createMeter = async (data: RegistryMeterForm) => {
	return callApi(`/v1/meter`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}

//Meters with readings
export const _getMetersWithReadings = async (
	meterFilters: MetersWithReadingsFilters,
) => {
	return callApi(
		`/v1/meters-with-readings${generateQueryParams(meterFilters)}`,
		{
			method: 'GET',
		},
	)
}

export const _getMeterWithReadings = async (meterUUID: string) => {
	return callApi(`/v1/meter-with-readings/${meterUUID}`, {
		method: 'GET',
	})
}

//Meter readings

export const _getMeterReadings = async (
	meterReadingFilters: MeterReadingFilters,
) => {
	return callApi(
		`/v1/meter-readings${generateQueryParams(meterReadingFilters)}`,
		{
			method: 'GET',
		},
	)
}

export const _getMeterReading = async (meterReadingUUID: string) => {
	return callApi(`/v1/meter-reading/${meterReadingUUID}`, {
		method: 'GET',
	})
}

export const _createMeterReading = async (data: MeterReadingForm) => {
	return callApi(`/v1/meter-reading`, {
		method: 'POST',
		body: {
			...data,
		},
	})
}
