import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DatePickerISO from 'ui/components/DatePickerISO'
import DropdownField from 'ui/components/DropdownField'
import type { MetersWithReadingsFilters as Filters } from '../types'
import type { ApartmentSelectOptions } from '../../types'

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	margin: 16px;
`

const Filter = styled.div`
	min-width: 160px;
`

type Props = {
	filters: Filters
	setFilters: React.Dispatch<React.SetStateAction<Filters>>
	apartmentOptions: ApartmentSelectOptions[]
}

const MetersWithReadingsFilters = ({
	filters,
	setFilters,
	apartmentOptions,
}: Props) => {
	const intl = useIntl()

	const filterFields = [
		{
			label: intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Label of apartment',
			}),
			value: filters.apartmentUUID,
			options: apartmentOptions,
			_onOptionSelect: (value) =>
				setFilters((prevData) => ({
					...prevData,
					apartmentUUID: value,
				})),
			hasSelectAll: true,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'dropdown',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Alkupäivä',
				description: 'Label of start date',
			}),
			_onOptionSelect: (value) => {
				if (!value) {
					setFilters((prevData) => ({
						...prevData,
						startDate: null,
					}))
				} else {
					setFilters((prevData) => ({
						...prevData,
						startDate: value,
					}))
				}
			},
			value: filters.startDate,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'date',
		},
		{
			label: intl.formatMessage({
				defaultMessage: 'Loppupäivä',
				description: 'Label of end date',
			}),
			_onOptionSelect: (value) => {
				if (!value) {
					setFilters((prevData) => ({
						...prevData,
						endDate: null,
					}))
				} else {
					setFilters((prevData) => ({
						...prevData,
						endDate: value,
					}))
				}
			},
			value: filters.endDate,
			nullSelectAll: true,
			itemLabel: 'label',
			itemValue: 'value',
			type: 'date',
		},
	]

	const renderFilterSelectField = (filterProps) => {
		if (filterProps.type === 'date') {
			return (
				<Filter key={filterProps.label}>
					<DatePickerISO
						key={filterProps.label}
						style={{ marginLeft: '20px' }}
						id={filterProps.label}
						label={filterProps.label}
						value={filterProps.value}
						onChange={filterProps._onOptionSelect}
					/>
				</Filter>
			)
		}
		if (filterProps.type === 'dropdown' && Array.isArray(filterProps.options)) {
			return (
				<Filter key={filterProps.label}>
					<DropdownField {...filterProps} />
				</Filter>
			)
		}
	}

	return <Container>{filterFields.map(renderFilterSelectField)}</Container>
}

export default MetersWithReadingsFilters
