import config from 'config'
import { FontIcon, Tab, Tabs } from 'react-md'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useIntl } from 'react-intl'
import MeterRegistryView from './MeterRegistryView'
import MetersWithReadingsView from './MetersWithReadings/MetersWithReadingsView'
import MeterReadingsView from './MeterReading/MeterReadingsView'
import { useBuildings } from 'ui/CompanyBuildings/hooks'
import useCompanyApartments from 'util/hooks/useCompanyApartments'
import { createApartmentOptions } from '../utils/apartmentOptions'

const HASH_METERS = config.routeHashes.meters
const HASH_METER_READINGS = config.routeHashes.meterReadings
const HASH_METER_FORMS = config.routeHashes.meterForms

const MeterRegistry = () => {
	const dispatch = useDispatch()
	const intl = useIntl()

	const selectedCompanyUUID: string = useSelector(
		({ app }: any) => app?.selectedFinanceCompanyUUID,
	)

	const { companyApartments } = useCompanyApartments(selectedCompanyUUID)
	const { buildings } = useBuildings(selectedCompanyUUID)
	const apartmentOptions = createApartmentOptions(companyApartments, buildings)

	const setPageMeters = () => {
		dispatch(push(HASH_METERS))
	}

	const setPageMeterReadings = () => {
		dispatch(push(HASH_METER_READINGS))
	}

	const setPageMeterForms = () => {
		dispatch(push(HASH_METER_FORMS))
	}

	const getTabIndex = () => {
		if (
			window.location.hash &&
			window.location.hash.includes(HASH_METER_READINGS)
		) {
			return 1
		}
		if (
			window.location.hash &&
			window.location.hash.includes(HASH_METER_FORMS)
		) {
			return 2
		}
		return 0
	}

	const getTabs = () => {
		const tabIndex = getTabIndex()

		return [
			<Tab
				key={HASH_METERS}
				style={
					tabIndex === 0
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Mittarit',
					description: 'Title of meter registry',
				})}
				icon={<FontIcon>sensors</FontIcon>}
				onClick={setPageMeters}
			/>,
			<Tab
				key={HASH_METER_READINGS}
				style={
					tabIndex === 1
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Mittarilukemat & Laskutus',
					description: 'Title of meters with readings',
				})}
				icon={<FontIcon>description</FontIcon>}
				onClick={setPageMeterReadings}
			/>,
			<Tab
				key={HASH_METER_FORMS}
				style={
					tabIndex === 2
						? {
								color: 'var(--color-primary-dark)',
								fontWeight: '700',
						  }
						: {
								color: 'var(--color-text-subtle)',
						  }
				}
				label={intl.formatMessage({
					defaultMessage: 'Mittausilmoitukset',
					description: 'Title of meter readings',
				})}
				icon={<FontIcon>description</FontIcon>}
				onClick={setPageMeterForms}
			/>,
		]
	}
	const tabIndex = getTabIndex()

	const renderPage = () => {
		let pageComponent
		if (tabIndex === 0) {
			pageComponent = (
				<MeterRegistryView
					selectedCompanyUUID={selectedCompanyUUID}
					apartmentOptions={apartmentOptions}
				/>
			)
		} else if (tabIndex === 1) {
			pageComponent = (
				<MetersWithReadingsView
					selectedCompanyUUID={selectedCompanyUUID}
					apartmentOptions={apartmentOptions}
				/>
			)
		} else if (tabIndex === 2) {
			pageComponent = (
				<MeterReadingsView
					selectedCompanyUUID={selectedCompanyUUID}
					apartmentOptions={apartmentOptions}
				/>
			)
		}

		return (
			<div
				className="flex-column flex-grow"
				style={{ overflowY: 'auto', height: 'auto', minHeight: '400px' }}
			>
				{pageComponent}
			</div>
		)
	}

	return (
		<div className="flex-column" style={{ flexGrow: 1, overflow: 'hidden' }}>
			<Tabs
				tabId="users-mobile-tab"
				mobile
				style={{
					flexShrink: 0,
					borderBottomStyle: 'solid',
					borderBottomColor: 'rgba(0,0,0,0.2)',
					borderBottomWidth: 1,
				}}
				alignToKeyline={false}
				defaultTabIndex={tabIndex}
				indicatorHeight={3}
			>
				{getTabs()}
			</Tabs>
			{renderPage()}
		</div>
	)
}

export default MeterRegistry
