export enum InvoicingMethod {
	E_INVOICE = 'eInvoice',
	WEB_INVOICE = 'webInvoice',
	PAPAER_INVOICE = 'paperInvoice',
}

export enum BondName {
	TENANT = 'tenant',
	SHAREHOLDER = 'shareholder',
	SHAREHOLDER_NOT_REGISTERED = 'shareholder_not_registered',
}

export enum PaymentProductBillingBasis {
	AREA = 'area',
	AREA_ADJUSTED = 'area-adjusted',
	SHARE_COUNT = 'share-count',
	RESIDENT_COUNT = 'resident-count',
	PIECES = 'pieces',
}

export enum InvoiceStatus {
	SAVED = 'saved',
	CREATED = 'created',
	SENT = 'sent',
	PAID = 'paid',
	VOID = 'void',
	VOID_BY_UPDATION = 'void_by_updation',
}
export const ActiveInvoiceStatus = [
	InvoiceStatus.SENT,
	InvoiceStatus.PAID,
] as const

export const VoidedInvoiceStatus = [
	InvoiceStatus.VOID,
	InvoiceStatus.VOID_BY_UPDATION,
] as const

export const InvoiceStatusConfig: Record<
	InvoiceStatus,
	{ icon: string; color: string }
> = {
	[InvoiceStatus.SAVED]: { icon: 'save', color: 'teal' },
	[InvoiceStatus.CREATED]: { icon: 'create', color: 'teal' },
	[InvoiceStatus.SENT]: { icon: 'send', color: 'orange' },
	[InvoiceStatus.PAID]: { icon: 'check_circle', color: 'green' },
	[InvoiceStatus.VOID]: { icon: 'block', color: 'red' },
	[InvoiceStatus.VOID_BY_UPDATION]: { icon: 'block', color: 'red' },
}

export enum TaxPeriodLength {
	MONTH = 'month',
	QUARTER = 'quarter',
	YEAR = 'year',
}

export enum Operator {
	'012345' = 'Apix',
}

export enum Direction {
	INVOICE = 'invoice',
}

export enum CountryCode {
	FI = 'FI',
}

export enum InvoicingMonth {
	JANUARY = 1,
	FEBRUARY = 2,
	MARCH = 3,
	APRIL = 4,
	MAY = 5,
	JUNE = 6,
	JULY = 7,
	AUGUST = 8,
	SEPTEMBER = 9,
	OCTOBER = 10,
	NOVEMBER = 11,
	DECEMBER = 12,
}

export enum AccountsLedgerGroupBy {
	APARTMENT = 'apartment',
	CONTRACT = 'contract',
	PARTY = 'party',
}

export enum AccountsLedgerView {
	ACTUALS = 'actuals',
	FORECAST = 'forecast',
}

export enum MeterLocation {
	APARTMENT = 'apartment',
	COMPANY = 'company',
}

export enum WaterReadingMethod {
	MANUAL_COLD = 'manual_cold',
	MANUAL_HOT = 'manual_hot',
	MANUAL_COLD_HOT = 'manual_cold_hot',
	REMOTE_COLD = 'remote_cold',
	REMOTE_HOT = 'remote_hot',
	REMOTE_COLD_HOT = 'remote_cold_hot',
}

export enum ElectricityReadingMethod {
	MANUAL = 'manual',
	MANUAL_DAY_NIGH = 'manual_day_night',
	REMOTE = 'remote',
}

export enum HeatingReadingMethod {
	MANUAL = 'manual',
	REMOTE = 'remote',
}

export enum ReadingStatus {
	INITIAL_READING = 'initial_reading',
	BASE_READING = 'base_reading',
	FINAL_READING = 'final_reading',
}

export type ReadingMethodKey =
	| keyof typeof WaterReadingMethod
	| keyof typeof ElectricityReadingMethod
	| keyof typeof HeatingReadingMethod

export const MeterType = {
	WATER: {
		MeterReadingMethod: WaterReadingMethod,
	},
	ELECTRICITY: {
		MeterReadingMethod: ElectricityReadingMethod,
	},
	HEATING: {
		MeterReadingMethod: HeatingReadingMethod,
	},
} as const

export type MeterTypeKey = keyof typeof MeterType
