import type { IntlShape } from 'react-intl'
import type {
	MeterReadingFormHeader,
	MeterReadingHeader,
	MeterWithReadingsHeader,
	RegistryMeterFormHeader,
	RegistryMeterHeader,
} from './types'
import { MeterLocation } from '../enum'

export const registryMeterHeaders: RegistryMeterHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittari',
				description: 'Table column header for meter registry: Meter',
			}),
		key: 'meter',
		show: true,
		sortable: true,
		type: 'string',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column header for meter registry: Apartment',
			}),
		key: 'apartmentUUID',
		show: true,
		sortable: true,
		type: 'apartmentOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Table column header for meter registry: Type',
			}),
		key: 'type',
		show: true,
		sortable: true,
		type: 'meterType',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sisäänlukutapa',
				description: 'Table column header for meter registry: Reading method',
			}),
		key: 'readingMethod',
		show: true,
		sortable: true,
		type: 'readingMethod',
	},
]

export const registryMeterFormHeaders: RegistryMeterFormHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittari',
				description: 'Meter',
			}),
		key: 'meter',
		type: 'text',
		show: true,
		required: true,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sijainti',
				description: 'Location',
			}),
		key: 'location',
		type: 'locationOptions',
		show: true,
		required: true,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Apartment',
			}),
		key: 'apartmentUUID',
		type: 'apartmentOptions',
		show: true,
		required: true,
		editable: false,
		showKey: 'location',
		showValue: MeterLocation.APARTMENT,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Type',
			}),
		key: 'type',
		type: 'typeOptions',
		show: true,
		required: true,
		editable: false,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sisäänlukutapa',
				description: 'Reading method',
			}),
		key: 'readingMethod',
		type: 'readingMethodOptions',
		show: true,
		required: true,
		editable: false,
		optionsFormKey: 'type',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Lisätieto',
				description: 'Additional information',
			}),
		key: 'additionalInfo',
		type: 'text',
		show: true,
		required: false,
		editable: false,
	},
]

export const registryMeterApartmentsHeaders: RegistryMeterFormHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Type',
			}),
		key: 'type',
		type: 'typeOptions',
		required: true,
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Sisäänlukutapa',
				description: 'Reading method',
			}),
		key: 'readingMethod',
		type: 'readingMethodOptions',
		required: true,
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tunnuksen etuliite',
				description: 'Prefix of the identifier',
			}),
		key: 'prefix',
		type: 'text',
		required: false,
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tunnuksen loppuliite',
				description: 'Suffix of the identifier',
			}),
		key: 'suffix',
		type: 'text',
		required: false,
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tyhjän tilan korvaava merkki',
				description: 'Delimiter for empty space',
			}),
		key: 'delimiter',
		type: 'text',
		required: true,
		show: true,
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage:
					'Lisää uusi mittari huoneistolle, vaikka huoneistolta mittari jo löytyisikin',
				description: 'Add new meter to apartment even if it already has one',
			}),
		key: 'addNewMeterEvenIfExists',
		type: 'yesNoOptions',
		required: true,
		show: true,
	},
]

export const meterWithReadingsHeaders: MeterWithReadingsHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'UUID',
				description: 'Table column header for meter reading: UUID',
			}),
		key: 'uuid',
		show: false,
		sortable: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittari',
				description: 'Table column header for meter reading: Meter',
			}),
		key: 'meter',
		show: true,
		sortable: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Huoneisto',
				description: 'Table column header for meter reading: Apartment',
			}),
		key: 'apartmentUUID',
		show: true,
		sortable: true,
		type: 'apartmentOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Tyyppi',
				description: 'Table column header for meter reading: Type',
			}),
		key: 'type',
		show: true,
		sortable: true,
		type: 'meterType',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Laskutuspäivä',
				description: 'Table column header for meter reading: Invoicing date',
			}),
		key: 'invoicingDate',
		show: true,
		sortable: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viimeisin mittauspäivä',
				description:
					'Table column header for meter reading: Latest reading date',
			}),
		key: 'latestReadingDate',
		show: true,
		sortable: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Viimeisin mittausarvo',
				description: 'Table column header for meter reading: Latest reading',
			}),
		key: 'latestReadingValue',
		show: true,
		sortable: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Alkupvm',
				description: 'Table column header for meter reading: Start date',
			}),
		key: 'startDate',
		show: true,
		sortable: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Loppupvm',
				description: 'Table column header for meter reading: End date',
			}),
		key: 'endDate',
		show: true,
		sortable: true,
		type: 'date',
	},
]

export const meterReadingHeaders: MeterReadingHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'UUID',
				description: 'Table column header for meter reading: UUID',
			}),
		key: 'uuid',
		show: false,
		sortable: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittari',
				description: 'Table column header for meter reading: Meter',
			}),
		key: 'meter',
		show: true,
		sortable: true,
		type: 'meter',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Arvo',
				description: 'Table column header for meter reading: Value',
			}),
		key: 'value',
		show: true,
		sortable: true,
		type: 'text',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Status',
				description: 'Table column header for meter reading: Status',
			}),
		key: 'status',
		show: true,
		sortable: true,
		type: 'statusOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittauspäivä',
				description: 'Table column header for meter reading: Date',
			}),
		key: 'date',
		show: true,
		sortable: true,
		type: 'date',
	},
]

export const meterReadingFormHeaders: MeterReadingFormHeader[] = [
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Mittari',
				description: 'Table column header for meter reading form: Meter',
			}),
		key: 'meterUUID',
		show: true,
		type: 'meterOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Status',
				description: 'Table column header for meter reading form: Status',
			}),
		key: 'status',
		show: true,
		type: 'statusOptions',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Päivämäärä',
				description: 'Table column header for meter reading form: Date',
			}),
		key: 'date',
		show: true,
		type: 'date',
	},
	{
		title: (intl: IntlShape) =>
			intl.formatMessage({
				defaultMessage: 'Arvo',
				description: 'Table column header for meter reading form: Value',
			}),
		key: 'value',
		show: true,
		type: 'number',
	},
]
