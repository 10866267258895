const emulatorTypeEnabled = (type) => {
	if (typeof process.env.REACT_APP_FIREBASE_EMULATORS !== 'string') return false
	return process.env.REACT_APP_FIREBASE_EMULATORS.includes(type)
}
const useFunctionsEmulator = emulatorTypeEnabled('functions')
const useFirestoreEmulator = emulatorTypeEnabled('firestore')
const useStorageEmulator = emulatorTypeEnabled('storage')

export default {
	strongAuth: {
		authHost: 'https://tunnistus.telia.fi',
		clientId: '4ecd0cfb-bbf0-46dd-ac78-b61dc27e11fb',
		callbackUrl: 'https://app.vii.lu/internal/id_callback',
	},
	// NOTE (08/2023): concerns mostly companyRole, but the 'none' role is also used in managerRole logic.
	// Changing these values should be done very carefully.
	// NOTE: when adding a new role, add a translation for it in CompanyUsers/messages
	userRoles: {
		none: 'none', // ei roolia
		userTenant: 'user_tenant', // asukas
		userShareholder: 'user_shareholder', // osakas
		userManager: 'user_manager', // isännöitsijä (used mostly for visuals)
		userExpert: 'user_expert', // asiantuntija
		userCEO: 'user_chief_executive_officer', // toimitusjohtaja
		userSecretary: 'user_secretary', // sihteeri
		admin: 'admin', // järjestelmänvalvoja
		adminBoardMember: 'admin_board_member', // hallituksen jäsen
		adminBoardDeputy: 'admin_board_deputy', // hallituksen varajäsen
		adminBoardDirector: 'admin_board_director', // hallituksen puheenjohtaja
		auditorAccounting: 'auditor_accounting', // tilintarkastaja
		auditorOperations: 'auditor_operations', // toiminnantarkastaja
		managerUser: 'manager_user',
		managerMaster: 'manager_master', // Master
		maintenanceCompany: 'maintenance_company', // huoltoyhtiö
	},
	functions: {
		baseURL: useFunctionsEmulator
			? 'http://localhost:5001/taloyhtio-app/europe-west1/'
			: 'https://europe-west1-taloyhtio-app.cloudfunctions.net/',
	},
	emulatorsEnabled: {
		firestore: useFirestoreEmulator,
		functions: useFunctionsEmulator,
		storage: useStorageEmulator,
	},
	ui: {
		MOBILE_MIN_WIDTH: 320,
		TABLET_MIN_WIDTH: 768,
		DESKTOP_MIN_WIDTH: 1025,
		bulletins: {
			maxBulletinHistory: 100,
		},
		surveys: {
			maxSurveysHistory: 100,
		},
		issues: {
			maxIssueHistory: 100,
		},
		messages: {
			messageMaxLength: 5000,
			messageMinLength: 2,
			pageLimit: 50,
			maxFileAttachments: 25, // used for threads as well
		},
		comments: {
			commentMaxLength: 5000,
			maxFileAttachments: 25,
		},
		memos: {
			memoMaxLength: 5000,
		},
		emergencies: {
			maxEmergencyHistory: 100,
		},
		threads: {
			topicPrefix: '_thread_', // for backend logic (should not be changed)
			width: 40, // percent of parent width
			msgBgOpacity: 0.22,
			maxTitleLengthMobile: 18,
			maxTitleLength: 36,
			pageLimit: 50,
		},
		infoscreens: {
			hearbeatThresholdWarning: 60000 * 20,
			heartbeatThresholdError: 60000 * 60,
			maxTabs: 15,
		},
	},
	maps: {
		key: 'AIzaSyDuH37pCrNCthOqYZJpsXTk35GnWzCOY1w',
	},
	routeHashes: {
		company: '#company',
		info: '#info',
		manager: '#manager',
		maintenance: '#maintenance',
		board: '#board',
		board_only: '#board_only',
		board_manager: '#board_manager',
		apartment: '#apartment',
		apartments: '#apartments',
		claims: '#claims',
		notifications: '#notifications',
		profile: '#profile',
		mentions: '#mentions',
		changelog: '#changelog',
		users: '#users',
		invites: '#invites',
		inviteRequests: '#invite-requests',
		support: '#support',
		service: '#service',
		reports: '#reports',
		integrations: '#integrations',
		devices: '#devices',
		meetingArchives: '#meeting-archives',
		emails: '#emails',
		minutes: '#minutes',
		minutesSigned: '#minutes-signed',
		summary: '#summary',
		attachments: '#attachments',
		parties: '#parties',
		bonds: '#bonds',
		basicInformation: '#basic-information',
		bankAccounts: '#bank-accounts',
		chartOfAccounts: '#chart-of-accounts',
		webInvoiceAddress: '#web-invoice-address',
		taxes: '#taxes',
		billing: '#billing',
		valueAddedTax: '#value-added-tax',
		invoices: '#invoices',
		invoiceActions: '#invoice-actions',
		consolidatedInvoice: '#consolidated-invoice',
		storageSpaces: '#storage-spaces',
		technicalInfo: '#technical-info',
		insurance: '#insurance',
		telecommunication: '#telecommunication',
		propertyId: '#property-id',
		landInfo: '#land-info',
		parkingSpaces: '#parking-spaces',
		apartmentsCommercialSpaces: '#apartments-commercial-spaces',
		separarteInvoicing: '#separate-invoicing',
		financialInformation: '#financial-information',
		redemptionAndTransferRestrictions: '#redemption-and-transfer-restrictions',
		meters: '#meters',
		meterReadings: '#meter-readings',
		meterForms: '#meter-forms',
	},
	trialDays: 30,
	contractURL:
		'https://firebasestorage.googleapis.com/v0/b/taloyhtio-app.appspot.com/o/static%2Fsopimusehdot.pdf?alt=media&token=646ba47e-df36-49cf-be50-db5e7fd3ea08',
	contractURLTrial:
		'https://firebasestorage.googleapis.com/v0/b/taloyhtio-app.appspot.com/o/static%2Fsopimusehdot_kokeilujakso.pdf?alt=media&token=768f5e9e-46c3-4c22-86c9-4c6a479b85e0',
	privacyURL: 'https://vii.lu/privacy',
	beamer: {
		key: 'b_XT6/S4ScxK2so4/xHWFRenn6kPSDuucJzfHSy+0loEs=',
	},
}
